<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/cycle' }">巡检计划</el-breadcrumb-item>
          <el-breadcrumb-item>编辑计划</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <!-- 基本信息 -->
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="巡检代号" prop="taskCode">
                <el-input v-model="form.taskCode" placeholder="请输入巡检代号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="起止日期" prop="date">
                <el-date-picker
                  style="width: 335px"
                  v-model="form.date"
                  type="daterange"
                  range-separator="至"
                  value-format="timestamp"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          
          <!-- 巡视点信息配置 -->
          <div class="config-title">
            <div class="form-title">
              巡视点信息<span style="color: red"> *</span>
            </div>
            <div>
              <el-button @click="addPatrol" type="primary" size="small">添加巡视点</el-button>
            </div>
          </div>
          <el-table :data="selectPatrolData" style="width: 100%">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="departmentName" label="所属部门"></el-table-column>
            <el-table-column prop="patrolName" label="巡视点名称"></el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <div>
                  <span @click="dltPatrol(scope.row)" style="color:red;cursor:pointer;">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <!-- 巡检人员配置 -->
          <div class="config-title">
            <div class="form-title">
              巡检人员配置<span style="color: red"> *</span>
            </div>
          </div>
          <el-row :gutter="20">
            <el-col :span="24" class="lubri-col">
              <div class="lubri-tabs">巡检周期配置</div>
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="自定义" name="custom">
                  <!-- 自定义配置 -->
                  <!-- <div style="text-align:right;">
                    <el-button @click="addCustomConfig" type="primary" size="small">增加自定义配置</el-button>
                  </div> -->
                  <div v-for="(item, key) in customConfig" :key="key" class="config-box" style="padding-top:30px;">
                    <!-- <div class="title">
                      <div style="display: flex;">
                        <span class="iconfont icon-peizhi" style="font-size: 20px;margin-right: 5px;"></span>
                        <span> 自定义配置 {{key + 1}} </span>
                      </div>
                      <div><i @click="customConfigDate(item, key)" v-if="key != 0" class="el-icon-close"></i></div>
                    </div> -->
                    <el-form-item label="巡检员">
                      <div @click="addCustomStaff(item, key)" class="staffBox">
                        <el-tag v-for="staff in item.staffInfos" :key="staff.staffId" style="margin-right: 4px;">
                          {{ staff.staffName }}
                        </el-tag>
                      </div>
                    </el-form-item>
                    <el-row :gutter="40">
                      <el-col :span="8">
                        <el-form-item label="首次工作日期" label-width="110px">
                          <el-date-picker
                            v-model="item.frequencyInfos[0].firstDate"
                            value-format="timestamp"
                            type="date"
                            placeholder="选择日期">
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-row :gutter="20" class="cycles" style="margin-left:30px;margin-right:-40px;">
                          <el-col :span="20">
                            <el-form-item label="工作天数">
                              <el-input v-model="item.frequencyInfos[0].workDay" type="number"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="1" style="padding-left:0;">
                            <el-form-item label="天" label-width="0px"></el-form-item>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="8">
                        <el-row :gutter="20" class="cycles" style="margin-left:-20px;margin-right:10px;">
                          <el-col :span="20">
                            <el-form-item label="间隔天数">
                              <el-input v-model="item.frequencyInfos[0].restDay" type="number"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="1" style="padding-left:0;">
                            <el-form-item label="天" label-width="0px"></el-form-item>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <div class="setWeek">
                        <div class="box">
                          <div class="week-title">
                            <el-form-item label="巡检工作时间段" label-width="115px">
                              <i @click="addLubriDate(item.frequencyInfos[0].frequencyTimeInfos)" class="el-icon-plus"></i>
                            </el-form-item>
                          </div>
                          <div v-for="(cDate, cIndex) in item.frequencyInfos[0].frequencyTimeInfos" :key="cIndex" class="date">
                            <span class="date-num">{{cIndex+1}}.</span>
                            开始时间：
                            <span style="position: relative;">
                              <lay-time :date="cDate.startTimeS" :index="[key, cIndex, 'startTimeS']" @change="laytimeLubriEnd"></lay-time>
                            </span>
                            
                            结束时间：
                            <span style="position: relative;">
                              <lay-time :date="cDate.endTimeS" :index="[key, cIndex, 'endTimeS']" @change="laytimeLubriEnd"></lay-time>
                            </span>
                            <i @click="deleteLubriDate(item.frequencyInfos[0].frequencyTimeInfos, cIndex, cDate)" v-if="cIndex != 0" class="el-icon-close" style="margin-top: 20px;"></i>
                          </div>
                        </div>
                      </div>
                    </el-row>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="周配置" name="weekConfig">
                  <!-- 周配置内容 -->
                  <div style="text-align:right;">
                    <el-button @click="addConfig" type="primary" size="small">增加周配置</el-button>
                  </div>
                  <div v-for="(item, key) in ruleStaffDetail" :key="key" class="config-box">
                    <div class="title">
                      <div style="display: flex;">
                        <span class="iconfont icon-peizhi" style="font-size: 20px;margin-right: 5px;"></span>
                        <span> 周配置 {{key + 1}} </span>
                      </div>
                      <div><i @click="configDate(item, key)" v-if="key != 0" class="el-icon-close" style="float: right"></i></div>
                    </div>
                    <el-form-item label="巡检员" prop="name">
                      <div @click="addStaff(item, key)" class="staffBox">
                        <el-tag v-for="staff in item.staffInfos" :key="staff.staffId" style="margin-right: 4px;">
                          {{ staff.staffName }}
                        </el-tag>
                      </div>
                    </el-form-item>
                    <el-form-item label="工作时间">
                      <el-checkbox-group v-model="item.weekList" @change="checkboxChange(item)" >
                        <el-checkbox :label="1">星期一</el-checkbox>
                        <el-checkbox :label="2">星期二</el-checkbox>
                        <el-checkbox :label="3">星期三</el-checkbox>
                        <el-checkbox :label="4">星期四</el-checkbox>
                        <el-checkbox :label="5">星期五</el-checkbox>
                        <el-checkbox :label="6">星期六</el-checkbox>
                        <el-checkbox :label="0">星期日</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                    <div class="setWeek">
                      <div class="box" v-for="(value, vKey) in item.rsd" :key="vKey">
                        <div class="week-title">
                          <template v-if="value.weekIndex == 1">星期一</template>
                          <template v-if="value.weekIndex == 2">星期二</template>
                          <template v-if="value.weekIndex == 3">星期三</template>
                          <template v-if="value.weekIndex == 4">星期四</template>
                          <template v-if="value.weekIndex == 5">星期五</template>
                          <template v-if="value.weekIndex == 6">星期六</template>
                          <template v-if="value.weekIndex == 0">星期日</template>
                          <i @click="addWeekDate(value.arr, value.frequencyId)" class="el-icon-plus"></i>
                        </div>
                        <div class="date" v-for="(date, index) in value.arr" :key="index">
                          <span class="date-num">{{index+1}}.</span>
                          开始时间：
                          <span style="position: relative;">
                            <lay-time :date="date.startTimeS" :index="[key, vKey, index, 'startTimeS']" @change="laytimeEnd"></lay-time>
                            <!-- <el-time-picker
                              v-on:focus="setTimeActive(index + 'L' + vKey)"
                              v-on:blur="clearTimeActive()"
                              v-model="date.startTimeS"
                              value-format="H:m:s"
                              placeholder="请选择">
                            </el-time-picker>
                            <div v-if="timeActive == index + 'L' + vKey" class="time-company">时<span>分</span>秒</div> -->
                          </span>
                          结束时间：
                          <span style="position: relative;">
                            <lay-time :date="date.endTimeS" :index="[key, vKey, index, 'endTimeS']" @change="laytimeEnd"></lay-time>
                            <!-- <el-time-picker
                              v-on:focus="setTimeActive(index + 'R' + vKey)"
                              v-on:blur="clearTimeActive()"
                              v-model="date.endTimeS"
                              value-format="H:m:s"
                              placeholder="请选择">
                            </el-time-picker>
                            <div v-if="timeActive == index + 'R' + vKey" class="time-company">时<span>分</span>秒</div> -->
                          </span>
                          <i @click="deleteDate(value, index, date)" v-if="index != 0" class="el-icon-close" style="margin-top: 20px;"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/cycle')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
      <!-- 巡视点侧滑 -->
      <el-drawer
        title="添加巡视点"
        :with-header="false"
        :visible.sync="patrolDrawer"
        direction="rtl"
        size="800px">
        <div class="drawer-title">添加巡视点</div>
        <div class="drawer-content table-tree">
          <div class="tree">
            <div class="tree-title">所属部门</div>
            <el-tree
              :data="departmentTreeList"
              node-key="id"
              @node-click="departmentClick"
              :props="patrolProps"
              :default-checked-keys="[1]"
              highlight-current
              :current-node-key="departmentId"
              ref="patrolTree">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span style="margin-right: 40px">{{ node.label }}</span>
              </span>
            </el-tree>
          </div>
          <div class="table">
            <el-table :data="tableDataCp" style="width: 500px" height="700" v-loading="patrolLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked" @change="checkeChange(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="patrolName" label="巡视点名称"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="patrolCancel">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="patrolDrawerOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
      <el-drawer
        title=""
        :with-header="false"
        :visible.sync="drawer"
        direction="rtl"
        size="900px">
        <div class="drawer-title">选择巡检员</div>
        <div class="drawer-content" v-if="drawer">
          <div class="table-tree">
            <div class="tree">
              <div class="tree-title"><i class="el-icon-share"></i> 结构</div>
              <el-tree
                :data="treeData"
                @node-click="treeRowClick"
                :props="defaultProps"
                :expand-on-click-node="false"
                default-expand-all
                ref="tree">
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
                </span>
              </el-tree>
            </div>
            
            <div class="table">
              <el-table :data="tableData" style="width: 100%" height="700" v-loading="tableLoading" element-loading-spinner="el-icon-loading">
                <el-table-column width="40">
                  <template slot-scope="scope">
                    <!-- {{staffListID.includes(scope.row.id)}} -->
                    <!-- v-model="scope.row.check" -->
                    <el-checkbox v-model="scope.row.check" :checked="staffListID.includes(scope.row.id)" @change="checked(scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="sex" label="性别">
                  <template slot-scope="scope">
                    <span v-if="scope.row.sex == 1">男</span>
                    <span v-else>女</span>
                  </template>
                </el-table-column>
                <el-table-column prop="departmentName" label="部门"></el-table-column>
                <el-table-column prop="name" label="岗位">
                  <template slot-scope="scope">
                    <div
                      v-for="item in scope.row.positionInfoList"
                      :key="item.positionId"
                    >{{item.positionName}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="电话"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button type="primary" size="small" @click="drawer=false">取消</el-button>
          <el-button size="small" type="primary" @click="drawerOK" class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import layTime from '@/components/layTime'
export default {
  components: {
    layTime
  },
  data() {
    return {
      loading: false,
      patrolLoading:false,
      tableLoading: false,
      timeActive: '',
      submit: {
        addPatrolIds: []
      },
      rules: {
        taskCode:[{ required: true, message: '必填项', trigger: 'blur' }],
        patrols:[{ required: true, message: '必填项', trigger: 'blur' }],
        departmentId:[{ required: true, message: '必填项', trigger: 'change' }],
        date:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
       // tabs
      activeName:'custom',
      patrolDrawer: false,
      departmentId: 0,
      departmentName: '',
      departmentTreeList: [],
      tableDataCp:[],
      tableSelectList: [],
      selectPatrolData: [],
      patrolProps: {
        children: 'childTree',
        label: 'name'
      },

      drawer: false,
      bumen: [],
      tableData: [],
      treeData: [],
      staffListID: [],
      checkedStaffList: [],
      customConfigKey: '',
      configKey: '',
      form: {
        startDate: 0,
        endDate: 0,
        patrols: [],
        date: [],
        departmentId: 0
      },
      defaultProps: {
        children: 'childTree',
        label: 'name'
      },
      row: {},
      customConfig: [], // 自定义配置
      ruleStaffDetail: [], // 周配置
      patrolList: [],
      rulePatrolDetail: [], // 巡视点数据
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d < n;
        }
      },
      data: {
        addPatrolIds: [], // 新增巡视点
        deletePatrolIds: [], // 删除巡视点
        
        deleteGroupIds: [], // 删除组ID
        deleteFrequencyIds: [], // 星期ID
        deleteStaffRelationIds: [], //  人员ID

        deleteFrequencyTimeIds: [],
        endDate: 0,
        startDate: 0,
        taskRuleId: 0,
        taskCode: '',
        staffGroups: []
      },

      addCustomStaffInfos: [], // 新增自定义人员
      deleteCustomStaffRelationIds: [], // 删除自定义人员ID
      deleteCustomFrequencyTimeIds: [], // 频次时间段ID

      addStaffInfos: [], // 新增人员
      deleteGroupIds: [], // 删除组ID
      deleteFrequencyIds: [], // 星期ID
      deleteStaffRelationIds: [], // 人员ID
      deleteFrequencyTimeIds: [], // 频次时间段ID
    }
  },
  methods: {
    dayjs,
    // 添加巡视点
    addPatrol(){
      this.patrolDrawer = true
      console.log(this.selectPatrolData)
      this.tableDataCp.forEach((v)=>{ 
        if (this.selectPatrolData.find(item => item.id == v.id)) {
          v.checked = true
        }else {
          v.checked = false
        }
      })
    },
    // 点击部门获取对应巡视点
    departmentClick(data,node){
      if(this.tableSelectList.length==0){
        this.tableDataCp = []
        this.tableSelectList = []
        this.selectPatrolData = []
        this.getPatrolList(data.id, data.name)
      }else {
        if(this.tableSelectList[0].departmentId==data.id) {
          this.$refs.patrolTree.setCurrentKey(data.id)
        }else{
          node.isCurrent = false
          this.$refs.patrolTree.setCurrentKey(this.tableSelectList[0].departmentId)
          this.$message.error("不能跨部门选择巡视点信息！");
        }
      }
    },
    getPatrolList(id, name){
      this.patrolLoading = true
      this.$ajax.post("patrolList", {
        departmentId: id
      }).then((res) => {
        res.data.forEach(v => {
          v.checked = false
          v.departmentId = id
          v.departmentName = name
          this.tableDataCp.push(v)
        })
        this.patrolLoading = false
      }).catch(err=>{
        this.patrolLoading = false
      })
    },
    // 勾选设备
    checkeChange(row) {
      if (row.checked) {
        // 勾选
        this.tableSelectList.push(row)
      } else {
        this.tableSelectList.forEach((item, index) => {
          if (item.id == row.id) {
            this.tableSelectList.splice(index, 1)
          }
        })
      }
    },
    patrolCancel() {
      this.patrolDrawer = false
    },
    // 选择巡视点点击确定
    patrolDrawerOK(){
      this.patrolDrawer = false
      this.selectPatrolData = []
      this.tableSelectList.forEach(v=>{
        this.selectPatrolData.push(v)
      })
    },
    // 删除巡视点信息
    dltPatrol(row){
      this.selectPatrolData.forEach((item, index) => {
        if (item.id == row.id) {
          this.selectPatrolData.splice(index, 1)
        }
      })
      this.tableSelectList.forEach((v,k) => {
        if (v.id == row.id) {
          this.tableSelectList.splice(k, 1)
        }
      })
    },
    customConfigDate(item, index) {
      this.$delete(this.customConfig, index)
    },
    configDate(item, index) {
      this.$delete(this.ruleStaffDetail, index)
      this.deleteGroupIds.push(item.staffGroupId)
      item.frequencyInfos.forEach(d => {
        this.data.deleteFrequencyIds.push(d.frequencyId)
      })
    },
    laytimeEnd(index, date) {
      this.ruleStaffDetail[index[0]].rsd[index[1]].arr[index[2]][index[3]] = date
    },
    setTimeActive(val) {
      setTimeout(() => {
        this.timeActive = val
      }, 150)
    },
    clearTimeActive() {
      this.timeActive = ''
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let _this = this
          let unsub = true
          let formData = {
            addFrequencyInfos: [],
            addFrequencyTimeInfos: [],
            addStaffGroupInfos: [],
            addStaffInfos: [],
            deleteFrequencyIds: [],
            deleteFrequencyTimeIds: [],
            deleteStaffGroupIds: [],
            deleteStaffRelationIds: [],
            editCycleFrequencyDate: {}, //新增字段
            editFrequencyTimeInfos: [],
            taskRuleBaseInfo: {
              addPatrolIds: [],
              deletePatrolIds: [],
              endDate: 0,
              startDate: 0,
              taskCode: '',
              departmentId: 0,
              frequencyType: null //新增字段
            },
            taskRuleId: 0
          }

          // 编辑巡检计划基础信息
          this.rulePatrolDetail.forEach(item => { // 删除巡视点
            let inIs = this.selectPatrolData.find(function(obj) {
              return obj.id == item.id
            })
            if (!inIs) {
              formData.taskRuleBaseInfo.deletePatrolIds.push(item.id)
            }
          })

          this.selectPatrolData.forEach(item => { // 新增巡视点
            let inIs = this.rulePatrolDetail.find(function(obj) {
              return obj.id == item.id
            })
            if (!inIs) {
              formData.taskRuleBaseInfo.addPatrolIds.push(item.id)
            }
          })

          formData.taskRuleBaseInfo.endDate = this.form.date[1]
          formData.taskRuleBaseInfo.startDate = this.form.date[0]
          formData.taskRuleBaseInfo.taskCode = this.form.taskCode
          formData.taskRuleBaseInfo.frequencyType = this.activeName == 'weekConfig' ? 0 : 1
          formData.taskRuleId = this.form.taskRuleId
          this.selectPatrolData.forEach(v=>{
            formData.taskRuleBaseInfo.departmentId = v.departmentId
          })
          
          if (this.selectPatrolData.length == 0) {
            this.$message.error("巡视点信息不能为空！");
            return
          }

          if(this.activeName=='custom') {
            // 当前处于自定义模式下
            let customConfig = this.customConfig.every(v => v.staffInfos.length != 0)
            let firstDate = this.customConfig.every(v => v.frequencyInfos[0].firstDate != null)
            let workDay = this.customConfig.every(v => v.frequencyInfos[0].workDay != null && v.frequencyInfos[0].workDay != '')
            let restDay = this.customConfig.every(v => v.frequencyInfos[0].restDay != null && v.frequencyInfos[0].restDay != '')
            let frequencyTimeInfos = this.customConfig.every(v => v.frequencyInfos[0].frequencyTimeInfos.length != 0)
            if(!customConfig) {
              this.$message.error("巡检人员不能为空！");
              return
            }
            if(!firstDate) {
              this.$message.error("首次工作日期不能为空！");
              return
            }
            if(!workDay) {
              this.$message.error("工作天数不能为空！");
              return
            }
            if(!restDay) {
              this.$message.error("间隔天数不能为空！");
              return
            }
            if(!frequencyTimeInfos){
              this.$message.error("巡检工作时间段不能为空！");
              return
            }

            this.customConfig.forEach(item => {
              let staffRelations = []
              let cycleFrequencyInfos = {}
              item.staffInfos.forEach(staff => {
                staffRelations.push({
                  staffId: staff.staffId
                })
              })
              item.frequencyInfos.forEach(fre => {
                let frequencyTimeInfos = []
                fre.frequencyTimeInfos.forEach(freTimes => {
                  // 新增频次时间段
                  if(!freTimes.frequencyId) {
                    if(fre.frequencyId != undefined) {
                      formData.addFrequencyTimeInfos.push({
                        frequencyId: fre.frequencyId,
                        endTime: this.dateInt(freTimes.endTimeS), 
                        startTime: this.dateInt(freTimes.startTimeS)
                      })
                    }
                  }
                  // 编辑频次时间段
                  if(freTimes.frequencyTimeId) {
                    if(freTimes.endTimeC != freTimes.endTimeS || freTimes.startTimeC != freTimes.startTimeS) {
                      formData.editFrequencyTimeInfos.push({
                        endTime: this.dateInt(freTimes.endTimeS),
                        frequencyTimeId: freTimes.frequencyTimeId,
                        startTime: this.dateInt(freTimes.startTimeS)
                      })
                    }
                  }

                  frequencyTimeInfos.push({
                    endTime: _this.dateInt(freTimes.endTimeS),
                    startTime: _this.dateInt(freTimes.startTimeS),
                  })
                  if (_this.dateInt(freTimes.startTimeS) > _this.dateInt(freTimes.endTimeS)) {
                    unsub = false
                  }
                })
                // 新增自定义模块信息
                cycleFrequencyInfos = {
                  firstDate: fre.firstDate,
                  frequencyTimeInfos: frequencyTimeInfos,
                  restDay: parseInt(fre.restDay),
                  workDay: parseInt(fre.workDay),
                }
                // 编辑自定义模块信息
                if(fre.frequencyId) {
                  if(fre.firstDate != fre.firstDateC || fre.restDay != fre.restDayC || fre.workDay != fre.workDayC) {
                    formData.editCycleFrequencyDate = {
                      firstDate: fre.firstDate,
                      frequencyId: fre.frequencyId,
                      restDay: parseInt(fre.restDay),
                      workDay: parseInt(fre.workDay),
                    }
                  }
                }
              })

              // 新增自定义配置信息
              if(!item.staffGroupId) {
                formData.addStaffGroupInfos.push({
                  staffRelationInfos: staffRelations,
                  cycleFrequencyInfos: cycleFrequencyInfos
                })
              }
            })

            // 新增人员配置
            formData.addStaffInfos = this.addCustomStaffInfos
            // 删除人员
            formData.deleteStaffRelationIds = this.deleteCustomStaffRelationIds
            // 删除时间段
            formData.deleteFrequencyTimeIds = this.deleteCustomFrequencyTimeIds

            // 删除组ID(星期转自定义)
            if(this.ruleStaffDetail.length!=0) {
              this.ruleStaffDetail.forEach(i => {
                formData.deleteStaffGroupIds.push(i.staffGroupId)
              })
            }
          }else {
            // 当前处于周期模式下
            let staffFlag = this.ruleStaffDetail.every(v => v.staffInfos.length != 0)
            let weekFlag = this.ruleStaffDetail.every(v => v.weekList.length != 0)
            if(this.ruleStaffDetail.length==0) {
              this.$message.error("请增加一条周配置项！");
              return
            }
            if(!staffFlag) {
              this.$message.error("巡检人员不能为空！");
              return
            }
            if(!weekFlag) {
              this.$message.error("工作时间不能为空！");
              return
            }

            this.ruleStaffDetail.forEach(item => {
              let staffRelations = []
              let frequencyInfos = []
              item.staffInfos.forEach(staff => {
                staffRelations.push({
                  staffId: staff.staffId
                })
              })
              item.rsd.forEach(rsd => {
                let frequencyTimeInfos = []
                rsd.arr.forEach( arr => {
                  // 新增频次时间段
                  if(!arr.frequencyId) {
                    if(rsd.frequencyId != undefined) {
                      formData.addFrequencyTimeInfos.push({
                        frequencyId: rsd.frequencyId,
                        endTime: this.dateInt(arr.endTimeS), 
                        startTime: this.dateInt(arr.startTimeS)
                      })
                    }
                  }
                  // 编辑频次时间段
                  if(arr.frequencyTimeId) {
                    if(arr.endTimeC != arr.endTimeS || arr.startTimeC != arr.startTimeS) {
                      formData.editFrequencyTimeInfos.push({
                        endTime: this.dateInt(arr.endTimeS),
                        frequencyTimeId: arr.frequencyTimeId,
                        startTime: this.dateInt(arr.startTimeS)
                      })
                    }
                  }

                  frequencyTimeInfos.push({
                    endTime: _this.dateInt(arr.endTimeS),
                    startTime: _this.dateInt(arr.startTimeS),
                  })
                  if (_this.dateInt(arr.startTimeS) > _this.dateInt(arr.endTimeS)) {
                    unsub = false
                  }
                })
                // 新增频次（星期）
                if(item.staffGroupId) {
                  if(!rsd.frequencyId) {
                    formData.addFrequencyInfos.push({
                      frequencyTimeInfos: frequencyTimeInfos,
                      staffGroupId: item.staffGroupId,
                      weekIndex: rsd.weekIndex
                    })
                  }
                }
                
                frequencyInfos.push({
                  frequencyTimeInfos: frequencyTimeInfos,
                  weekIndex: rsd.weekIndex
                })
              })
              // 新增周配置信息
              if(!item.staffGroupId) {
                formData.addStaffGroupInfos.push({
                  staffRelationInfos: staffRelations,
                  frequencyInfos: frequencyInfos
                })
              }
            })

            // 新增人员配置
            formData.addStaffInfos = this.addStaffInfos
            // 删除人员
            formData.deleteStaffRelationIds = this.deleteStaffRelationIds
            // 删除频次
            formData.deleteFrequencyIds = this.deleteFrequencyIds
            // 删除时间段
            formData.deleteFrequencyTimeIds = this.deleteFrequencyTimeIds
            // 删除组ID
            formData.deleteStaffGroupIds = this.deleteGroupIds

            // 删除组ID(自定义转星期)
            if(this.customConfig.length!=0) {
              this.customConfig.forEach(i => {
                if(i.staffGroupId) {
                  formData.deleteStaffGroupIds.push(i.staffGroupId)
                }
              })
            }
          }

          if (unsub) {
            this.loading = true
            this.$ajax.post('ruleUpdate', formData).then(res => {
              this.loading = false
              this.$message.success("成功");
              this.$router.push("/cycle");
            }).catch(err=>{
              this.loading = false
            })
          }else {
            this.$message.error("开始时间 不能大于 结束时间！");
          }
        }
      })
    },
    dateInt(str) {
      let strArr = str.split(':')
      let second =  (strArr[0] * 3600) + (strArr[1] * 60) + Number(strArr[2])
      return second
    },
    drawerOK() {
      this.drawer = false
      if(this.activeName == 'custom') {
        this.customConfig[this.customConfigKey].staffInfos = [].concat(this.checkedStaffList)
      }else {
        this.ruleStaffDetail[this.configKey].staffInfos = [].concat(this.checkedStaffList)
      }
      // this.form.config[this.configKey].staffListID = [].concat(this.staffListID)
      this.customConfig.forEach((v, k) => {
        v.staffInfos.forEach((item, index) => {
          if(!item.staffRelationId) {
            if(v.staffGroupId != undefined) {
              let inIs = this.addCustomStaffInfos.find(function(obj) {
                return obj.staffId == item.staffId
              })
              if(!inIs) {
                this.addCustomStaffInfos.push({
                  staffGroupId: v.staffGroupId,
                  staffId: item.staffId
                })
              }
            }
          }
        })
      })

      this.ruleStaffDetail.forEach((v, k) => {
        v.staffInfos.forEach((item, index) => {
          if(!item.staffRelationId) {
            if(v.staffGroupId != undefined) {
              let inIs = this.addStaffInfos.find(function(obj) {
                return obj.staffId == item.staffId
              })
              if(!inIs) {
                this.addStaffInfos.push({
                  staffGroupId: v.staffGroupId,
                  staffId: item.staffId
                })
              }
            }
          }
        })
      })
    },
    handleClick(tab, event){},
    addCustomStaff(item, key) {
      this.loadTableData(this.treeData[0].id)
      this.customConfigKey = key
      this.staffListID = []
      item.staffInfos.forEach(item => {
        this.staffListID.push(item.staffId)
      })
      this.checkedStaffList = [].concat(item.staffInfos)
      this.drawer = true
    },
    addStaff(item, key) {
      this.loadTableData(this.treeData[0].id)
      this.configKey = key
      this.staffListID = []
      item.staffInfos.forEach(item => {
        this.staffListID.push(item.staffId)
      })
      this.checkedStaffList = [].concat(item.staffInfos)
      this.drawer = true
    },
    checked(row) {
      if (!this.staffListID.includes(row.id)) {
        this.checkedStaffList.push({
          staffId: row.id,
          staffName: row.name,
          isNew: true
        })
        this.staffListID.push(row.id)
      } else {
        this.checkedStaffList.forEach((item, index) => {
          console.log(item)
          if (item.staffId == row.id) {
            if (item.isNew == undefined) {
              // this.data.deleteStaffRelationIds.push(item.staffId)

              this.deleteCustomStaffRelationIds.push(item.staffRelationId)
              this.deleteStaffRelationIds.push(item.staffRelationId)
            }
            this.checkedStaffList.splice(index, 1)
            this.$delete(this.staffListID, index)
          }
        })
        this.addCustomStaffInfos.forEach((v, k) => {
          if(v.staffId == row.id) {
            this.addCustomStaffInfos.splice(k, 1)
          }
        })
        this.addStaffInfos.forEach((v, k) => {
          if(v.staffId == row.id) {
            this.addStaffInfos.splice(k, 1)
          }
        })
      }
    },
    loadTableData(id) {
      this.tableLoading = true
      this.$ajax.post('queryFilter', {
        departmentId: id ? id : 0
      }).then(res => {
        res.data.forEach(item => {
          let inIs = this.staffListID.find(function(obj) {
            return obj == item.id
          })
          if (!inIs) {
            item.check = false
          }else {
            item.check = true
          }
        })
        this.tableData = res.data
        this.tableLoading = false
      }).catch(err=>{
        this.tableLoading = false
      })
    },
    treeRowClick(data) {
      this.loadTableData(data.id)
    },
    deleteDate(value, index, data) {
      if (data.frequencyTimeId != undefined) {
        this.deleteFrequencyTimeIds.push(data.frequencyTimeId)
      }
      this.$delete(value.arr, index)
    },
    loadTreeData() {
      this.$ajax.post('departmentResponsibleChild').then(res => {
        this.treeData = res.data
      })
    },
    addWeekDate(arr, frequencyId) {
      // item.dates.push({date: []})
      arr.push({endTimeS: '23:00:00', startTimeS: '00:00:00'})
    },
    checkboxChange(item) {
      if (item.rsd.length < item.weekList.length ) { // 增加
        for (let i=0; i < item.weekList.length; i++) {
          let findIs = item.rsd.find(function(rsd) {
            return rsd.weekIndex == item.weekList[i]
          })

          if (findIs == undefined) {
            item.rsd.push({
              weekIndex: item.weekList[i],
              arr: [{
                startTimeS: '00:00:00',
                endTimeS: '23:00:00'
              }]
            })
            break
          }
        }
      } else { // 减少
        item.rsd.forEach((d, index)  => {
          let inIs = item.weekList.includes(d.weekIndex)
          if (!inIs) {
            this.deleteFrequencyIds.push(d.frequencyId)
            this.$delete(item.rsd, index)
          }
        })
      }
    },
    addCustomConfig() {
      this.customConfig.push({
        staffInfos: [],
        frequencyInfos:[{
          firstDate: null,
          workDay: null,
          restDay: null,
          frequencyTimeInfos: []
        }],
      })
    },
    addConfig() {
      this.ruleStaffDetail.push({
        staffInfos: [],
        weekList: [],
        rsd: []
      })
    },
    loadPatrolList() {
      this.$ajax.post("patrolList", {
        facilityId: ''
      }).then((res) => {
        this.patrolList = res.data;
      });
    },
    // 自定义增加时间段
    addLubriDate(arr){
      arr.push({endTimeS: '23:00:00', startTimeS: '00:00:00'})
    },
    // 自定义删除时间段
    deleteLubriDate(arr, cIndex, data){
      if (data.frequencyTimeId != undefined) {
        this.deleteCustomFrequencyTimeIds.push(data.frequencyTimeId)
      }
      this.$delete(arr, cIndex)
    },
    // 自定义选择时间段
    laytimeLubriEnd(index, date){
      this.customConfig[index[0]].frequencyInfos[0].frequencyTimeInfos[index[1]][index[2]] = date
    },
    dateSecond(val) {
      let s = val % 60
      let _s = val - s

      let m = (_s / 60) % 60

      let h = (_s / 60 - m) / 60
      return `${h>9 ? h:'0'+h}:${m>9 ? m:'0'+m}:${s>9 ? s:'0'+s}`
    },
    loadBaseDetail() {
      this.$ajax.post('rulebaseDetail', {
        taskRuleId: this.row.id
      }).then(res => {
        sessionStorage.setItem("detail", true);
        this.$set(this.form, 'taskCode', res.data.taskCode)
        this.form.taskRuleId = res.data.taskRuleId
        this.form.date = [res.data.startDate, res.data.endDate]
        this.form.taskCode = res.data.taskCode
        this.departmentId = res.data.departmentId
        this.departmentName = res.data.departmentName
        this.loadPatrolDetail()
      })
    },
    load_bumen() {
      this.$ajax.get("departmentTree").then((res) => {
        this.bumen = this.getTreeData(res.data);
      });
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    loadPatrolDetail() {
      this.loading = true
      this.$ajax.post('rulePatrolDetail', {
        taskRuleId: this.row.id
      }).then(res => {
        res.data.forEach(v=>{
          this.rulePatrolDetail.push({
            checked: true,
            departmentId: this.departmentId,
            departmentName: this.departmentName,
            id: v.patrolId,
            patrolName: v.patrolName
          })
        })
        res.data.forEach(v=>{
          this.selectPatrolData.push({
            checked: true,
            departmentId: this.departmentId,
            departmentName: this.departmentName,
            id: v.patrolId,
            patrolName: v.patrolName
          })
        })
        res.data.forEach(v=>{
          this.tableSelectList.push({
            checked: true,
            departmentId: this.departmentId,
            departmentName: this.departmentName,
            id: v.patrolId,
            patrolName: v.patrolName
          })
        })
        this.departmentList()
        this.loading = false
        // this.$set(this.form, 'patrols', this.patrolsString(res.data))
      }).catch(err=>{
        this.loading = false
      })
    },

    loadStaffDetail() {
      this.$ajax.post('ruleStaffDetail', {
        taskRuleId: this.row.id
      }).then(res => {
        let _this = this
        let sss = res.data
        sss.forEach(item => {
          this.$set(item, 'weekList', getWeekList(item))
          this.$set(item, 'rsd', getRsd(item))
        })
        
        function getWeekList(item) {
          let arr = []
          item.frequencyInfos.forEach(obj => {
            if (!arr.includes(obj.weekIndex)) {
              arr.push(obj.weekIndex)
            }
          })
          return arr
        }
        function getRsd(item) {
          let arr = []
          item.frequencyInfos.forEach(obj => {
            let isNew = true
            if(obj.firstDate) {
              obj.firstDateC = obj.firstDate
              obj.restDayC = obj.restDay
              obj.workDayC = obj.workDay
            }

            obj.frequencyTimeInfos.forEach(i => {
              i.endTimeC = _this.dateSecond(i.endTime)
              i.startTimeC = _this.dateSecond(i.startTime)

              i.endTimeS = _this.dateSecond(i.endTime)
              i.startTimeS = _this.dateSecond(i.startTime)
              arr.forEach(arrItem => {
                if (arrItem.weekIndex == obj.weekIndex) {
                  arrItem.arr.push(i)
                  isNew = false
                }
              })
              if (isNew) {
                arr.push({
                  frequencyId: obj.frequencyId,
                  weekIndex: obj.weekIndex,
                  arr: [i]
                })
              }
            })
          })
          return arr
        }

        if(_this.row.frequencyType==0) {
          this.customConfig.push({
            staffInfos: [],
            frequencyInfos:[{
              firstDate: null,
              workDay: null,
              restDay: null,
              frequencyTimeInfos: []
            }],
          })
          _this.ruleStaffDetail = sss
          console.log(_this.ruleStaffDetail)
        }else {
          _this.customConfig = sss
          console.log(_this.customConfig)
        }
      })
    },

    patrolsString(arr) {
      let d = []
      if (arr) {
        arr.forEach(item => {
          d.push(item.patrolId)
        })
      }
      return d;
    },

    departmentList(){
      this.$ajax.get("departmentResponsibleSecondLevel").then((res) => {
        this.departmentTreeList = res.data;
        
        res.data.forEach(v=>{
          let inIs = this.rulePatrolDetail.find(function(obj) {
            return obj.departmentId == v.id
          })
          if (inIs) {
            this.$nextTick(()=>{
              this.getPatrolList(v.id, v.name)
            })
          }
        })
      })
    }
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push('/cycle')
      return
		}
    this.row = this.$route.params.row
    if(this.row.frequencyType==0) {
      this.activeName = 'weekConfig'
    }else {
      this.activeName = 'custom'
    }
    this.loadBaseDetail()
    this.loadStaffDetail()
    this.loadTreeData()
  },
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-title {
  font-weight: bold;
}
.config-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 110px)';
  margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.config-box {
  box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border: 1px solid #ebeef5;
  border-radius: 10px;
  margin: 20px 0;
  .title {
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
  }
}
.staffBox {
  cursor: pointer;
  border: 1px solid #DCDFE6;
  border-radius: 5px;
  margin-right: 140px;
  padding: 4px;
  min-height: 40px;
  transition: all .3s ease;
}
.staffBox:hover {
  border: 1px solid #409EFF;
}
.el-icon-close {
  color: #1494fb;
  margin-left: 20px;
  cursor: pointer;
  font-size: 20px;
}
.setWeek {
  border-top: 0;
  .date {
    padding-bottom: 20px;
    padding-left: 80px;
    font-size: 14px;
  }
  .date-num {
    font-size: 12px;
    margin: 0 10px;
  }
  .week-title {
    font-size: 12px;
    padding-bottom: 10px;
    margin-left: 35px;
    span {
      margin-right: 20px;
    }
    // padding-bottom: 10px;
    i {
      font-weight: bold;
      color: #1494fb;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .title {
    font-size: 14px;
    padding-left: 10px;
    line-height: 30px;
    background-color: #f6f7fb;
  }
  .box {
    padding: 0 10px;
    margin-bottom: 6px;
  }
  .box:last-child {
    margin: 0;
  }
}
.time-company {
  position: absolute;
  left: 2px;
  top: 41px;
  z-index: 3000;
  background: #f1f1f1;
  padding: 4px 23px;
  line-height: 30px;
  border-bottom: 1px solid #ccc;
  span {
    padding: 0 45px;
  }
}
.lubri-col /deep/.el-tabs__nav{
  left: 140px;
}
.lubri-col /deep/.el-tabs__nav-wrap::after {
  position: static !important;
}
.lubri-tabs {
  font-size:14px;
  font-weight: bold;
  color:#606266;
  line-height: 40px;
  margin-left: 30px;
  position: absolute;
}
.cycles /deep/.el-input__inner {
  text-align: center;
} 
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
.drawer-bottom {
  position: static;
  z-index: 1;
  text-align: right;
  padding: 10px 20px 10px 0;
}
</style>
<style lang="less">
.el-drawer.rtl {
  overflow: scroll;
}
</style>